var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BulkTags',{attrs:{"visible":_vm.showBulkTags,"addOn":_vm.selectedAddOnTab},on:{"updateAddOnTag":_vm.updateAddOnTag,"closeBulkTags":_vm.closeBulkTags}}),_c('a-tabs',{attrs:{"size":"large"},model:{value:(_vm.selectedAddOnTab),callback:function ($$v) {_vm.selectedAddOnTab=$$v},expression:"selectedAddOnTab"}},[_c('a-tab-pane',{key:"parking",attrs:{"tab":"Parking Spot"}},[_c('h3',{staticClass:"mt-3"},[_vm._v("Overview")]),_c('div',{staticClass:"dF",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.parkingOverview),function(stat){return _c('div',{key:stat.value,staticClass:"overview-card mb-3 mr-3",staticStyle:{"width":"140px"}},[_c('h5',{staticStyle:{"min-height":"45px"}},[_vm._v(_vm._s(stat.label))]),_c('div',{staticStyle:{"font-size":"25px","margin-top":"10px"},style:(`color:${stat.color}`)},[_vm._v(" "+_vm._s(_vm.addonCount(stat.value, "parking"))+" ")])])}),0),_c('div',{staticClass:"my-4 jSB dF"},[_c('div',{staticClass:"dF aC"},[_c('h3',{staticClass:"mr-3"},[_vm._v("Parking Spots")]),_c('div',{staticClass:"dF",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.parkingOverview),function(stat){return _c('div',{key:stat.value,staticClass:"px-3 py-1 mb-2 mr-2",staticStyle:{"border-radius":"4px"},style:(stat.value != 'total'
								? `background-color:${stat.color};`
								: 'border:1px solid #D0C9D6')},[_c('a-checkbox',{style:(stat.value != 'total' &&
										stat.value != 'hold'
										? 'color:#FFF'
										: ''),attrs:{"checked":_vm.selectType.includes(stat.value)},on:{"change":(e) => _vm.changeSelect(stat.value, e)}},[_vm._v(_vm._s(stat.select))])],1)}),0)]),_c('div',{staticClass:"dF aC",staticStyle:{"gap":"20px"}},[(_vm.selectParking.length > 1)?_c('MoreActions',{attrs:{"shape":"button","buttonText":"Bulk Actions","items":_vm.bulkActions},on:{"action":_vm.bulkActioned}}):_vm._e(),_c('div',{staticClass:"dF aC px-3 py-1 mx-2",staticStyle:{"border-radius":"3px","background-color":"#fff","cursor":"pointer","border":"1px solid #ecebed"},on:{"click":_vm.openFilter}},[_c('div',[_vm._v("Filters")]),_c('svg',{staticClass:"feather feather-filter ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polygon',{attrs:{"points":"22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"}})])])],1)]),(_vm.condoAddons &&
					_vm.condoAddons.parking &&
					_vm.condoAddons.parking.length
					)?_c('div',{staticClass:"grid-display-2"},_vm._l((_vm.condoAddons.parking),function(addon){return _c('div',{key:addon.id},[_c('AddonCard',{attrs:{"id":addon.id,"type":'parking',"selectAddon":_vm.selectParking},on:{"toggleSelect":_vm.toggleSelect,"updateStatus":function($event){(_vm.$p == 10 && addon.status != 'sold') || _vm.$p >= 40
									? _vm.openModal(
										addon,
										'updateStatus',
										'parking'
									)
									: _vm.$message.error(
										'You do not have permission to update the status'
									)},"applyTags":_vm.updateTags}})],1)}),0):_c('div',[_c('EmptyApp',{attrs:{"text":'No parking spots found for your filter',"img":'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'}})],1)]),_c('a-tab-pane',{key:"lockers",attrs:{"tab":"Locker"}},[_c('h3',{staticClass:"mt-3"},[_vm._v("Overview")]),_c('div',{staticClass:"dF",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.lockerOverview),function(stat){return _c('div',{key:stat.value,staticClass:"overview-card mb-3 mr-3",staticStyle:{"width":"140px"}},[_c('h5',[_vm._v(_vm._s(stat.label))]),_c('div',{staticStyle:{"font-size":"25px","margin-top":"20px"},style:(`color:${stat.color}`)},[_vm._v(" "+_vm._s(_vm.addonCount(stat.value, "lockers"))+" ")])])}),0),_c('div',{staticClass:"my-4 jSB dF"},[_c('div',{staticClass:"dF aC"},[_c('h3',{staticClass:"mr-3"},[_vm._v("Lockers")]),_c('div',{staticClass:"dF",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.lockerOverview),function(stat){return _c('div',{key:stat.value,staticClass:"px-3 py-1 mb-2 mr-2",staticStyle:{"border-radius":"4px"},style:(stat.value != 'total'
								? `background-color:${stat.color};`
								: 'border:1px solid #D0C9D6')},[_c('a-checkbox',{style:(stat.value != 'total' &&
										stat.value != 'hold'
										? 'color:#FFF'
										: ''),attrs:{"checked":_vm.selectType.includes(stat.value)},on:{"change":(e) => _vm.changeSelect(stat.value, e)}},[_vm._v(_vm._s(stat.select))])],1)}),0)]),_c('div',{staticClass:"dF aC",staticStyle:{"gap":"20px"}},[(_vm.selectLockers.length > 1)?_c('MoreActions',{attrs:{"shape":"button","buttonText":"Bulk Actions","items":_vm.bulkActions},on:{"action":_vm.bulkActioned}}):_vm._e(),_c('div',{staticClass:"dF aC px-3 py-1 mx-2",staticStyle:{"border-radius":"3px","background-color":"#fff","cursor":"pointer","border":"1px solid #ecebed"},on:{"click":_vm.openFilter}},[_c('div',[_vm._v("Filters")]),_c('svg',{staticClass:"feather feather-filter ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polygon',{attrs:{"points":"22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"}})])])],1)]),(_vm.condoAddons &&
					_vm.condoAddons.lockers &&
					_vm.condoAddons.lockers.length
					)?_c('div',{staticClass:"grid-display-2"},_vm._l((_vm.condoAddons.lockers),function(addon){return _c('div',{key:addon.id},[_c('AddonCard',{attrs:{"id":addon.id,"type":'lockers',"selectAddon":_vm.selectLockers},on:{"toggleSelect":_vm.toggleSelect,"updateStatus":function($event){(_vm.$p == 10 && addon.status != 'sold') || _vm.$p >= 40
									? _vm.openModal(
										addon,
										'updateStatus',
										'lockers'
									)
									: _vm.$message.error(
										'You do not have permission to update the status'
									)},"applyTags":_vm.updateTags}})],1)}),0):_c('div',[_c('EmptyApp',{attrs:{"text":'No lockers found for your filter',"img":'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'}})],1)]),_c('a-tab-pane',{key:"bikeRacks",attrs:{"tab":"Bike Rack"}},[_c('h3',{staticClass:"mt-3"},[_vm._v("Overview")]),_c('div',{staticClass:"dF",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.bikeRackOverview),function(stat){return _c('div',{key:stat.value,staticClass:"overview-card mb-3 mr-3",staticStyle:{"width":"140px"}},[_c('h5',[_vm._v(_vm._s(stat.label))]),_c('div',{staticStyle:{"font-size":"25px","margin-top":"20px"},style:(`color:${stat.color}`)},[_vm._v(" "+_vm._s(_vm.addonCount(stat.value, "bikeRacks"))+" ")])])}),0),_c('div',{staticClass:"my-4 jSB dF"},[_c('div',{staticClass:"dF aC"},[_c('h3',{staticClass:"mr-3"},[_vm._v("Bike Racks")]),_c('div',{staticClass:"dF",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.bikeRackOverview),function(stat){return _c('div',{key:stat.value,staticClass:"px-3 py-1 my-1 mr-2",staticStyle:{"border-radius":"4px"},style:(stat.value != 'total'
								? `background-color:${stat.color};`
								: 'border:1px solid #D0C9D6')},[_c('a-checkbox',{style:(stat.value != 'total' &&
										stat.value != 'hold'
										? 'color:#FFF'
										: ''),attrs:{"checked":_vm.selectType.includes(stat.value)},on:{"change":(e) => _vm.changeSelect(stat.value, e)}},[_vm._v(_vm._s(stat.select))])],1)}),0)]),_c('div',{staticClass:"dF aC",staticStyle:{"gap":"20px"}},[(_vm.selectBikeRacks.length > 1)?_c('MoreActions',{attrs:{"shape":"button","buttonText":"Bulk Actions","items":_vm.bulkActions},on:{"action":_vm.bulkActioned}}):_vm._e(),_c('div',{staticClass:"dF aC px-3 py-1 mx-2",staticStyle:{"border-radius":"3px","background-color":"#fff","cursor":"pointer","border":"1px solid #ecebed"},on:{"click":_vm.openFilter}},[_c('div',[_vm._v("Filters")]),_c('svg',{staticClass:"feather feather-filter ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polygon',{attrs:{"points":"22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"}})])])],1)]),(_vm.condoAddons &&
					_vm.condoAddons.bikeRacks &&
					_vm.condoAddons.bikeRacks.length
					)?_c('div',{staticClass:"grid-display-2"},_vm._l((_vm.condoAddons.bikeRacks),function(addon){return _c('div',{key:addon.id},[_c('AddonCard',{attrs:{"id":addon.id,"type":'bikeRacks',"selectAddon":_vm.selectBikeRacks},on:{"toggleSelect":_vm.toggleSelect,"updateStatus":function($event){(_vm.$p == 10 && addon.status != 'sold') || _vm.$p >= 40
									? _vm.openModal(
										addon,
										'updateStatus',
										'bikeRacks'
									)
									: _vm.$message.error(
										'You do not have permission to update the status'
									)},"applyTags":_vm.updateTags}})],1)}),0):_c('div',[_c('EmptyApp',{attrs:{"text":'No bike racks found for your filter',"img":'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'}})],1)])],1),_c('a-modal',{attrs:{"visible":_vm.modal.visible,"centered":true,"footer":null},on:{"cancel":_vm.onClose}},[(_vm.modal.object.name)?_c('h5',[_vm._v(" "+_vm._s(_vm.modal.addonType == "parking" ? "Parking" : _vm.modal.addonType == "lockers" ? "Locker" : "Bike Rack")+" #"+_vm._s(_vm.modal.object.name)+" "),_c('span',{staticClass:"ml-3",style:(_vm.modal.object.status == 'available'
					? 'color:#1EC48C'
					: _vm.modal.object.status == 'sold'
						? 'color:#FD6C83'
						: _vm.modal.object.status == 'hold'
							? 'color:#FECE5B'
							: 'color:#9693E8')},[_vm._v(_vm._s(_vm.modal.object.status.charAt(0).toUpperCase() + _vm.modal.object.status.slice(1)))])]):_vm._e(),(_vm.modal.type == 'updateStatus')?_c('a-form-model',{ref:"status",staticClass:"mt-5",attrs:{"model":_vm.status}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"label":"Status","required":"","rules":_vm.req('Please select one'),"prop":"status"}},[_c('a-select',{model:{value:(_vm.status.status),callback:function ($$v) {_vm.$set(_vm.status, "status", $$v)},expression:"status.status"}},_vm._l((_vm.statusOptions),function(stat){return _c('a-select-option',{key:stat.value,attrs:{"value":stat.value}},[_vm._v(_vm._s(stat.label))])}),1)],1)],1),(_vm.status.status)?_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"label":"Unit #","required":_vm.status.status !== 'available',"rules":_vm.req(
							'Please select unit',
							_vm.status.status !== 'available'
						),"prop":"unitId"}},[_c('a-select',{attrs:{"allowClear":true},model:{value:(_vm.status.unitId),callback:function ($$v) {_vm.$set(_vm.status, "unitId", $$v)},expression:"status.unitId"}},_vm._l((_vm.units),function(unit){return _c('a-select-option',{key:unit.id,attrs:{"value":unit.id}},[_vm._v("Unit #"+_vm._s(unit.unitNumber)+" - "+_vm._s(unit.salesStatus))])}),1)],1)],1):_vm._e(),(_vm.status.status == 'sold' ||
						_vm.status.status == 'conditional'
						)?_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"label":"Final Price","prop":"price"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"formatter":(price) =>
								`$ ${price}`.replace(
									/\B(?=(\d{3})+(?!\d))/g,
									','
								),"min":0},model:{value:(_vm.status.price),callback:function ($$v) {_vm.$set(_vm.status, "price", $$v)},expression:"status.price"}})],1)],1):_vm._e()],1)],1):(_vm.modal.type === 'applyTags')?_c('div',{staticClass:"py-3"},[_c('h5',[_vm._v("Apply Tags")]),_vm._l((_vm.$store.state.inventory.tags),function(item){return _c('div',{key:item.id,staticClass:"py-1 px-2 filter-options my-4",staticStyle:{"border":"#d0c9d6 1px solid","border-radius":"6px","width":"100%","cursor":"pointer","text-align":"center"},style:(_vm.applyingTags.includes(item.id)
					? 'background-color: #CAC3D0'
					: ''),on:{"click":function($event){return _vm.applyTags(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2):_vm._e(),_c('div',{staticClass:"dF jE",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"dF aC"},[_c('a-button',{staticStyle:{"border":"none !important","background-color":"#ece9f1","width":"150px"},style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v("CANCEL")]),(_vm.modal.type === 'applyTags')?_c('a-button',{staticStyle:{"width":"150px"},attrs:{"type":"primary"},on:{"click":_vm.updateTags}},[_vm._v("UPDATE TAGS")]):(_vm.modal.type === 'updateStatus')?_c('a-button',{staticStyle:{"width":"150px"},attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("UPDATE")]):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }