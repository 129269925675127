<template>
	<div>
		<BulkTags :visible="showBulkTags" @updateAddOnTag="updateAddOnTag" @closeBulkTags="closeBulkTags"
			:addOn="selectedAddOnTab" />
		<a-tabs size="large" v-model="selectedAddOnTab">
			<!-- Parking Tab -->
			<a-tab-pane key="parking" tab="Parking Spot">
				<h3 class="mt-3">Overview</h3>
				<div class="dF" style="flex-wrap: wrap;">
					<div v-for="stat in parkingOverview" :key="stat.value" class="overview-card mb-3 mr-3"
						style="width: 140px;">
						<h5 style="min-height: 45px;">{{ stat.label }}</h5>
						<div style="font-size: 25px; margin-top: 10px" :style="`color:${stat.color}`">
							{{ addonCount(stat.value, "parking") }}
						</div>
					</div>
				</div>
				<div class="my-4 jSB dF">
					<div class="dF aC">
						<h3 class="mr-3">Parking Spots</h3>
						<div class="dF" style="flex-wrap: wrap;">
							<div v-for="(stat) in parkingOverview" :key="stat.value" :style="stat.value != 'total'
								? `background-color:${stat.color};`
								: 'border:1px solid #D0C9D6'
								" style="border-radius: 4px" class="px-3 py-1 mb-2 mr-2">
								<a-checkbox @change="(e) => changeSelect(stat.value, e)"
									:checked="selectType.includes(stat.value)" :style="stat.value != 'total' &&
										stat.value != 'hold'
										? 'color:#FFF'
										: ''
										">{{ stat.select }}</a-checkbox>
							</div>
						</div>
					</div>
					<div class="dF aC" style="gap: 20px">
						<MoreActions v-if="selectParking.length > 1" shape="button" buttonText="Bulk Actions"
							:items="bulkActions" @action="bulkActioned" />
						<div @click="openFilter" class="dF aC px-3 py-1 mx-2" style="
		                        border-radius: 3px;
		                        background-color: #fff;
		                        cursor: pointer;
		                        border: 1px solid #ecebed;
		                    ">
							<div>Filters</div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
								stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
								class="feather feather-filter ml-2">
								<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
							</svg>
						</div>
					</div>
				</div>
				<div v-if="condoAddons &&
					condoAddons.parking &&
					condoAddons.parking.length
					" class="grid-display-2">
					<div v-for="addon in condoAddons.parking" :key="addon.id">
						<AddonCard @toggleSelect="toggleSelect" :id="addon.id" :type="'parking'"
							:selectAddon="selectParking" @updateStatus="
								($p == 10 && addon.status != 'sold') || $p >= 40
									? openModal(
										addon,
										'updateStatus',
										'parking'
									)
									: $message.error(
										'You do not have permission to update the status'
									)
								" @applyTags="updateTags" />
					</div>
				</div>
				<div v-else>
					<EmptyApp :text="'No parking spots found for your filter'"
						:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
				</div>
			</a-tab-pane>

			<!-- Locker Tab -->
			<a-tab-pane key="lockers" tab="Locker">
				<h3 class="mt-3">Overview</h3>
				<div class="dF" style="flex-wrap: wrap;">
					<div v-for="stat in lockerOverview" :key="stat.value" class="overview-card mb-3 mr-3"
						style="width: 140px;">
						<h5>{{ stat.label }}</h5>
						<div style="font-size: 25px; margin-top: 20px" :style="`color:${stat.color}`">
							{{ addonCount(stat.value, "lockers") }}
						</div>
					</div>
				</div>
				<div class="my-4 jSB dF">
					<div class="dF aC">
						<h3 class="mr-3">Lockers</h3>
						<div class="dF" style="flex-wrap: wrap;">
							<div v-for="(stat) in lockerOverview" :key="stat.value" :style="stat.value != 'total'
								? `background-color:${stat.color};`
								: 'border:1px solid #D0C9D6'
								" style="border-radius: 4px" class="px-3 py-1 mb-2 mr-2">
								<a-checkbox @change="(e) => changeSelect(stat.value, e)"
									:checked="selectType.includes(stat.value)" :style="stat.value != 'total' &&
										stat.value != 'hold'
										? 'color:#FFF'
										: ''
										">{{ stat.select }}</a-checkbox>
							</div>
						</div>
					</div>
					<div class="dF aC" style="gap: 20px">
						<MoreActions v-if="selectLockers.length > 1" shape="button" buttonText="Bulk Actions"
							:items="bulkActions" @action="bulkActioned" />
						<div @click="openFilter" class="dF aC px-3 py-1 mx-2" style="
		                        border-radius: 3px;
		                        background-color: #fff;
		                        cursor: pointer;
		                        border: 1px solid #ecebed;
		                    ">
							<div>Filters</div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
								stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
								class="feather feather-filter ml-2">
								<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
							</svg>
						</div>
					</div>
				</div>
				<div v-if="condoAddons &&
					condoAddons.lockers &&
					condoAddons.lockers.length
					" class="grid-display-2">
					<div v-for="addon in condoAddons.lockers" :key="addon.id">
						<AddonCard @toggleSelect="toggleSelect" :id="addon.id" :type="'lockers'"
							:selectAddon="selectLockers" @updateStatus="
								($p == 10 && addon.status != 'sold') || $p >= 40
									? openModal(
										addon,
										'updateStatus',
										'lockers'
									)
									: $message.error(
										'You do not have permission to update the status'
									)
								" @applyTags="updateTags" />
					</div>
				</div>
				<div v-else>
					<EmptyApp :text="'No lockers found for your filter'"
						:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
				</div>
			</a-tab-pane>

			<!-- Bike Rack Tab -->
			<a-tab-pane key="bikeRacks" tab="Bike Rack">
				<h3 class="mt-3">Overview</h3>
				<div class="dF" style="flex-wrap: wrap;">
					<div v-for="stat in bikeRackOverview" :key="stat.value" class="overview-card mb-3 mr-3"
						style="width: 140px;">
						<h5>{{ stat.label }}</h5>
						<div style="font-size: 25px; margin-top: 20px" :style="`color:${stat.color}`">
							{{ addonCount(stat.value, "bikeRacks") }}
						</div>
					</div>
				</div>
				<div class="my-4 jSB dF">
					<div class="dF aC">
						<h3 class="mr-3">Bike Racks</h3>
						<div class="dF" style="flex-wrap: wrap;">
							<div v-for="(stat) in bikeRackOverview" :key="stat.value" :style="stat.value != 'total'
								? `background-color:${stat.color};`
								: 'border:1px solid #D0C9D6'
								" style="border-radius: 4px" class="px-3 py-1 my-1 mr-2">
								<a-checkbox @change="(e) => changeSelect(stat.value, e)"
									:checked="selectType.includes(stat.value)" :style="stat.value != 'total' &&
										stat.value != 'hold'
										? 'color:#FFF'
										: ''
										">{{ stat.select }}</a-checkbox>
							</div>
						</div>
					</div>
					<div class="dF aC" style="gap: 20px">
						<MoreActions v-if="selectBikeRacks.length > 1" shape="button" buttonText="Bulk Actions"
							:items="bulkActions" @action="bulkActioned" />
						<div @click="openFilter" class="dF aC px-3 py-1 mx-2" style="
		                        border-radius: 3px;
		                        background-color: #fff;
		                        cursor: pointer;
		                        border: 1px solid #ecebed;
		                    ">
							<div>Filters</div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
								stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
								class="feather feather-filter ml-2">
								<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
							</svg>
						</div>
					</div>
				</div>
				<div v-if="condoAddons &&
					condoAddons.bikeRacks &&
					condoAddons.bikeRacks.length
					" class="grid-display-2">
					<div v-for="addon in condoAddons.bikeRacks" :key="addon.id">
						<AddonCard @toggleSelect="toggleSelect" :id="addon.id" :type="'bikeRacks'"
							:selectAddon="selectBikeRacks" @updateStatus="
								($p == 10 && addon.status != 'sold') || $p >= 40
									? openModal(
										addon,
										'updateStatus',
										'bikeRacks'
									)
									: $message.error(
										'You do not have permission to update the status'
									)
								" @applyTags="updateTags" />
					</div>
				</div>
				<div v-else>
					<EmptyApp :text="'No bike racks found for your filter'"
						:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'" />
				</div>
			</a-tab-pane>
		</a-tabs>

		<a-modal :visible="modal.visible" @cancel="onClose" :centered="true" :footer="null">
			<h5 v-if="modal.object.name">
				{{
					modal.addonType == "parking"
					? "Parking"
					: modal.addonType == "lockers"
						? "Locker"
						: "Bike Rack"
				}}
				#{{ modal.object.name }}
				<span class="ml-3" :style="modal.object.status == 'available'
					? 'color:#1EC48C'
					: modal.object.status == 'sold'
						? 'color:#FD6C83'
						: modal.object.status == 'hold'
							? 'color:#FECE5B'
							: 'color:#9693E8'
					">{{
		modal.object.status.charAt(0).toUpperCase() +
		modal.object.status.slice(1)
	}}</span>
			</h5>
			<a-form-model v-if="modal.type == 'updateStatus'" ref="status" :model="status" class="mt-5">
				<a-row :gutter="16">
					<a-col :span="24">
						<a-form-model-item label="Status" required :rules="req('Please select one')" prop="status">
							<a-select v-model="status.status">
								<a-select-option v-for="stat in statusOptions" :key="stat.value" :value="stat.value">{{
									stat.label }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<a-col :span="24" v-if="status.status">
						<a-form-model-item label="Unit #" :required="status.status !== 'available'" :rules="req(
							'Please select unit',
							status.status !== 'available'
						)
							" prop="unitId">
							<a-select v-model="status.unitId" :allowClear="true">
								<a-select-option v-for="unit in units" :key="unit.id" :value="unit.id">Unit #{{
									unit.unitNumber }} -
									{{ unit.salesStatus }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<a-col :span="24" v-if="status.status == 'sold' ||
						status.status == 'conditional'
						">
						<a-form-model-item label="Final Price" prop="price">
							<a-input-number style="width: 100%" v-model="status.price" :formatter="(price) =>
								`$ ${price}`.replace(
									/\B(?=(\d{3})+(?!\d))/g,
									','
								)
								" :min="0"></a-input-number>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div v-else-if="modal.type === 'applyTags'" class="py-3">
				<h5>Apply Tags</h5>
				<div v-for="item in $store.state.inventory.tags" :key="item.id" @click="applyTags(item.id)" :style="applyingTags.includes(item.id)
					? 'background-color: #CAC3D0'
					: ''
					" class="py-1 px-2 filter-options my-4" style="
                        border: #d0c9d6 1px solid;
                        border-radius: 6px;
                        width: 100%;
                        cursor: pointer;
                        text-align: center;
                    ">
					{{ item.name }}
				</div>
			</div>
			<div style="width: 100%" class="dF jE">
				<div class="dF aC">
					<a-button :style="{ marginRight: '8px' }" @click="onClose" style="
                            border: none !important;
                            background-color: #ece9f1;
                            width: 150px;
                        ">CANCEL</a-button>
					<a-button v-if="modal.type === 'applyTags'" @click="updateTags" type="primary"
						style="width: 150px">UPDATE TAGS</a-button>
					<a-button v-else-if="modal.type === 'updateStatus'" @click="submit" type="primary"
						style="width: 150px">UPDATE</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import AddonCard from "@/components/inventory/AddonCard";
import EmptyApp from "bh-mod/components/common/EmptyApp";
import MoreActions from "bh-mod/components/common/MoreActions";
import BulkTags from "@/components/inventory/BulkTags";

export default {
	components: {
		AddonCard,
		EmptyApp,
		MoreActions,
		BulkTags,
	},
	computed: {
		condoAddons() {
			let condoAddons = JSON.parse(
				JSON.stringify(this.$store.state.inventory.condoAddons)
			);

			if (this.$userType === 'agent') {
				Object.keys(condoAddons).forEach((addonType) => {
					condoAddons[addonType] = condoAddons[addonType].filter((addOn) => {
						if ((addOn.status === 'available' || (addOn.status === 'allocated' && addOn.allocatedTo === this.user.id))) {
							return true;
						}
						return false;
					})
				})
			}

			const orderAlpha = (array) => {
				array = array.sort((a, b) => {
					let aa = parseInt(a.name && a.name.toString().replace(/\D+/g, '') || 0) || 0;
					let bb = parseInt(b.name && b.name.toString().replace(/\D+/g, '') || 0) || 0;
					return aa - bb;
				});
				return array;
			};
			if (
				condoAddons.hasOwnProperty("parking") &&
				condoAddons.hasOwnProperty("lockers") &&
				condoAddons.hasOwnProperty("bikeRacks")
			) {
				condoAddons.parking = orderAlpha(condoAddons.parking);
				condoAddons.lockers = orderAlpha(condoAddons.lockers);
				condoAddons.bikeRacks = orderAlpha(condoAddons.bikeRacks);
			}

			let data = this.$store.state.inventory.addOnsFilter;
			let statuses = this.selectType;

			if (
				statuses[0] == "total" &&
				data.parkingType[0] == "any" &&
				data.lockersType[0] == "any" &&
				data.parkingPrice[1] == 0 &&
				data.lockersPrice[1] == 0 &&
				data.bikeRacksPrice[1] == 0 &&
				data.assignee[0] == "any" &&
				data.tags[0] == "any"
			) {
				return condoAddons;
			}

			const checkStatus = (addon, status) => {
				if (addon.status == null) addon.status = "draft";
				if (status[0] === "total") return true;
				if (status.includes("available")) {
					return status.includes(addon.status);
				}
				return status.includes(addon.status);
			};

			const checkPrice = (addOn, prices) => {
				if (prices[1] == 0) return true;

				if (addOn.price >= prices[0] && addOn.price <= prices[1]) {
					return true;
				} else return false;
			};

			const checkAssignee = (addOn, assignee) => {
				if (assignee[0] == "any") return true;

				let pass = true;
				if (assignee.includes("none") && !addOn.allocatedTo) {
					return pass;
				}
				else if (
					addOn.allocatedTo &&
					assignee.includes(addOn.allocatedTo)
				)
					return pass;
				else return false;
			};

			const checkType = (addOn, type) => {
				if (type[0] == "any") return true;

				let pass = true;
				if (
					addOn &&
					addOn.type &&
					type.includes(addOn.type)
				)
					return pass;
				else return false;
			};

			condoAddons.parking = condoAddons.parking.filter((addon) => {
				if (!checkStatus(addon, statuses)) return false;
				if (!checkAssignee(addon, data.assignee)) return false;
				if (!checkPrice(addon, data.parkingPrice)) return false;
				if (!checkType(addon, data.parkingType)) return false;
				return true;
			});
			condoAddons.lockers = condoAddons.lockers.filter((addon) => {
				if (!checkStatus(addon, statuses)) return false;
				if (!checkAssignee(addon, data.assignee)) return false;
				if (!checkPrice(addon, data.lockersPrice)) return false;
				if (!checkType(addon, data.lockersType)) return false;
				return true;
			});
			condoAddons.bikeRacks = condoAddons.bikeRacks.filter((addon) => {
				if (!checkStatus(addon, statuses)) return false;
				if (!checkAssignee(addon, data.assignee)) return false;
				if (!checkPrice(addon, data.bikeRacksPrice)) return false;
				return true;
			});

			return condoAddons;
		},
		origCondoAddons() {
			return this.$store.state.inventory.condoAddons;
		},
		condoSettings() {
			return this.$store.state.inventory.condoSettings;
		},
		filter() {
			return this.$store.state.inventory.highriseFilter;
		},
		instance() {
			return this.$store.state.instance;
		},
		user() {
			return this.$store.state.user.user
		},
		units() {
			return Object.values(this.$store.state.inventory.units).filter(
				(unit) => unit.buildingType === "condounits"
			);
		},

		parkingOverview() {
			let data = [
				{
					label: "Total Parking Spots",
					value: "total",
					color: "#2B93C6",
					select: "Select All",
				},
				{
					label: "Parking Spots Available",
					value: "available",
					color: "#1EC48C",
					select: "Available",
				},
				{
					label: "Parking Spots Sold",
					value: "sold",
					color: "#FD6C83",
					select: "Sold",
				},
				{
					label: "Parking Spots On Hold",
					value: "hold",
					color: "#FECE5B",
					select: "Hold",
				},
				{
					label: "Sold Conditional",
					value: "conditional",
					color: "#9693E8",
					select: "Sold Conditional",
				},
				{
					label: "Not Released",
					value: "notreleased",
					color: "#3F3356",
					select: "Not Released",
				}
			]

			if (this.instance.package && this.instance.package.itemCode === "PA-CON-HRP") {
				data.push(...[
					{
						label: "Parking Spots Allocated",
						value: "allocated",
						color: "var(--primary)",
						select: "Allocated",
					},
					{
						label: "Parking Spots Approved",
						value: "approved_for_reservation",
						color: "#134F5C",
						select: "Approved",
					},
					{
						label: "Parking Spots Reserved",
						value: "reserved",
						color: "#419E00",
						select: "Reserved",
					}
				])
			}

			return data
		},

		lockerOverview() {
			let data = [
				{
					label: "Total Lockers",
					value: "total",
					color: "#2B93C6",
					select: "Select All",
				},
				{
					label: "Lockers Available",
					value: "available",
					color: "#1EC48C",
					select: "Available",
				},
				{
					label: "Lockers Sold",
					value: "sold",
					color: "#FD6C83",
					select: "Sold",
				},
				{
					label: "Lockers On Hold",
					value: "hold",
					color: "#FECE5B",
					select: "Hold",
				},
				{
					label: "Sold Conditional",
					value: "conditional",
					color: "#9693E8",
					select: "Sold Conditional",
				},
				{
					label: "Not Released",
					value: "notreleased",
					color: "#3F3356",
					select: "Not Released",
				}
			]

			if (this.instance.package && this.instance.package.itemCode === "PA-CON-HRP") {
				data.push(...[
					{
						label: "Lockers Allocated",
						value: "allocated",
						color: "var(--primary)",
						select: "Allocated",
					},
					{
						label: "Lockers Approved",
						value: "approved_for_reservation",
						color: "#134F5C",
						select: "Approved",
					},
					{
						label: "Lockers Reserved",
						value: "reserved",
						color: "#419E00",
						select: "Reserved",
					}
				])
			}

			return data
		},

		bikeRackOverview() {
			let data = [
				{
					label: "Total Bike Racks",
					value: "total",
					color: "#2B93C6",
					select: "Select All",
				},
				{
					label: "Bike Racks Available",
					value: "available",
					color: "#1EC48C",
					select: "Available",
				},
				{
					label: "Bike Racks Sold",
					value: "sold",
					color: "#FD6C83",
					select: "Sold",
				},
				{
					label: "Bike Racks On Hold",
					value: "hold",
					color: "#FECE5B",
					select: "Hold",
				},
				{
					label: "Sold Conditional",
					value: "conditional",
					color: "#9693E8",
					select: "Sold Conditional",
				},
				{
					label: "Not Released",
					value: "notreleased",
					color: "#3F3356",
					select: "Not Released",
				}
			]

			if (this.instance.package && this.instance.package.itemCode === "PA-CON-HRP") {
				data.push(...[
					{
						label: "Bike Racks Allocated",
						value: "allocated",
						color: "var(--primary)",
						select: "Allocated",
					},
					{
						label: "Bike Racks Approved",
						value: "approved_for_reservation",
						color: "#134F5C",
						select: "Approved",
					},
					{
						label: "Bike Racks Reserved",
						value: "reserved",
						color: "#419E00",
						select: "Reserved",
					}
				])
			}

			return data
		}
	},
	data() {
		return {
			selectParking: [],
			selectLockers: [],
			selectBikeRacks: [],
			statusOptions: [
				{ value: "available", label: "Available" },
				{ value: "sold", label: "Sold" },
				{ value: "hold", label: "Hold" },
				{ value: "conditional", label: "Conditional" },
				{ value: "inventory", label: "Inventory" },
				{ value: "notreleased", label: "Not Released" },
			],
			status: {
				status: "",
				price: 0,
				unitId: "",
			},
			selectType: ["total"],
			modal: {
				visible: false,
				type: "",
				addonType: "",
				object: {},
			},
			showBulkTags: false,
			bulkActions: [
				{
					label: "Bulk Apply Tags",
					value: "tag",
				},
			],
			selectedAddOnTab: "parking",
			addOnTypes: {
				parking: "Parking",
				lockers: "Locker",
				bikeRacks: "Bike Rack",
			},
		};
	},
	methods: {
		addonCount(value, type) {
			if (type == "parking") {
				if (value != "total") {
					if (
						this.origCondoAddons.parking &&
						this.origCondoAddons.parking.length
					)
						return this.origCondoAddons.parking.filter(
							(addon) => addon.status == value
						).length;
					else return 0;
				} else {
					if (
						this.origCondoAddons.parking &&
						this.origCondoAddons.parking.length
					)
						return this.origCondoAddons.parking.length;
					else return 0;
				}
			} else if (type == "lockers") {
				if (value != "total") {
					if (
						this.origCondoAddons.lockers &&
						this.origCondoAddons.lockers.length
					)
						return this.origCondoAddons.lockers.filter(
							(addon) => addon.status == value
						).length;
					else return 0;
				} else {
					if (
						this.origCondoAddons.lockers &&
						this.origCondoAddons.lockers.length
					)
						return this.origCondoAddons.lockers.length;
					else return 0;
				}
			} else {
				if (value != "total") {
					if (
						this.origCondoAddons.bikeRacks &&
						this.origCondoAddons.bikeRacks.length
					)
						return this.origCondoAddons.bikeRacks.filter(
							(addon) => addon.status == value
						).length;
					else return 0;
				} else {
					if (
						this.origCondoAddons.bikeRacks &&
						this.origCondoAddons.bikeRacks.length
					)
						return this.origCondoAddons.bikeRacks.length;
					else return 0;
				}
			}
		},
		req: (msg, required = true) => ({ required: required, message: msg }),
		changeSelect(type, e) {
			if (this.selectType[0] == "total") this.selectType = [];
			if (type != "total") {
				if (this.selectType.includes(type)) {
					let index = this.selectType.findIndex((x) => x == type);
					this.selectType.splice(index, 1);
				} else {
					this.selectType.push(type);
				}
			} else {
				this.selectType = ["total"];
			}
			if (this.selectType.length == 0 || this.selectType.length == 5)
				this.selectType = ["total"];
		},
		updateTags(addOn) {
			this.updateAddOn(
				{
					...addOn,
				},
				this.selectedAddOnTab,
				false
			);
		},
		toggleSelect(id, type) {
			if (type === "parking") {
				if (this.selectParking.includes(id)) {
					let index = this.selectParking.findIndex((x) => x == id);
					this.selectParking.splice(index, 1);
				} else {
					this.selectParking.push(id);
				}
			} else if (type === "lockers") {
				if (this.selectLockers.includes(id)) {
					let index = this.selectLockers.findIndex((x) => x == id);
					this.selectLockers.splice(index, 1);
				} else {
					this.selectLockers.push(id);
				}
			} else if (type === "bikeRacks") {
				if (this.selectBikeRacks.includes(id)) {
					let index = this.selectBikeRacks.findIndex((x) => x == id);
					this.selectBikeRacks.splice(index, 1);
				} else {
					this.selectBikeRacks.push(id);
				}
			}
		},

		unSellAddOn(id, addonType) {
			if (!addonType) return;

			let self = this;
			const found = this.origCondoAddons[addonType].find(
				(addOn) => addOn.id === id
			);

			this.$confirm({
				title: `Unsell ${self.addOnTypes[addonType]}# ${found.name}`,
				content: (h) => (
					<h6 class="mt-4">Do you want to unsell this Add On?</h6>
				),
				okText: "Unsell",
				okType: "danger",
				cancelText: "Cancel",
				centered: true,
				onOk() {
					self.updateAddOn(
						{
							...found,
							status: "available",
						},
						addonType,
						false
					);
				},
			});
		},

		openModal(addon, type, aType) {
			this.modal.type = type;
			this.modal.object = JSON.parse(JSON.stringify(addon));
			this.status.unitId = this.modal.object.unitId;
			this.status.price = this.modal.object.price;
			this.status.status = addon.status;
			this.modal.addonType = aType;
			this.modal.visible = true;
		},
		submit() {
			if (this.modal.type == "updateStatus") {
				this.$refs.status.validate((valid) => {
					if (valid) {
						this.updateAddOn(
							{
								...this.modal.object,
								status: this.status.status,
								price: this.status.price,
								unitId: this.status.unitId,
							},
							this.modal.addonType,
							true
						);
					}
				});
			}
		},
		onClose() {
			if (this.modal.type == "updateStatus")
				this.$refs.status.resetFields();
			this.modal.visible = false;
			this.modal.type = "";
			this.modal.object = {};
			this.status = {
				status: "",
				price: 0,
				unitId: "",
			};
		},

		bulkActioned({ key }) {
			if (key == "tag") {
				this.showBulkTags = true;
			}
		},
		closeBulkTags() {
			this.showBulkTags = false;
		},

		updateAddOnTag({ tags, addOnType }) {
			const options = this.condoSettings.options;

			let selectedAddOnIds = [];
			if (addOnType === "parking") {
				selectedAddOnIds = this.selectParking;
			} else if (addOnType === "lockers") {
				selectedAddOnIds = this.selectLockers;
			} else if (addOnType === "bikeRacks") {
				selectedAddOnIds = this.selectBikeRacks;
			}

			options.addons[addOnType] = options.addons[addOnType].map(
				(addOn) => {
					if (
						selectedAddOnIds.includes(addOn.id) &&
						addOn.status === "available"
					) {
						return {
							...addOn,
							tags: [...addOn.tags, ...tags],
						};
					} else {
						return addOn;
					}
				}
			);

			this.$api
				.put(`/settings/:instance/condounits`, { options: options })
				.then(({ data }) => {
					this.$store.commit("UPDATE_CONDO_SETTINGS", data);
					this.selectParking = [];
					this.selectLockers = [];
					this.selectBikeRacks = [];
				})
				.catch((err) => {
					this.$message.error("An error occurred. Please try again");
					console.error("ERROR", err);
				})
				.finally(() => {
					this.closeBulkTags();
				});
		},

		updateAddOn(updatedAddOn, addOnType, maxCheck = false) {
			const sendObj = { options: {}, packages: [] };
			const newAddOn = JSON.parse(JSON.stringify(updatedAddOn));
			sendObj.options = JSON.parse(
				JSON.stringify(this.condoSettings.options)
			);

			const foundAddOn = sendObj.options.addons[addOnType].findIndex(
				(x) => x.id == newAddOn.id
			);

			if (foundAddOn !== -1)
				sendObj.options.addons[addOnType][foundAddOn] = newAddOn;

			const condoUnitId = newAddOn.unitId || this.modal.object.unitId;

			const unit = this.units.find((x) => x.id === condoUnitId);
			if (unit) {
				if (unit.packages && unit.packages.length) {
					const maxAddOnType =
						addOnType === "parking"
							? "maxParking"
							: addOnType === "lockers"
								? "maxLocker"
								: "maxBikeRack";

					if (
						maxCheck &&
						newAddOn.unitId &&
						unit.packages[0] &&
						unit.packages[0][maxAddOnType] &&
						unit.packages[0][maxAddOnType] <=
						unit.packages[0].other.addons[addOnType].length
					) {
						return this.$message.error(
							`The selected unit has reached the maximum number of ${this.addOnTypes[addOnType]}.`
						);
					}
					if (!unit.packages[0].other.addons) {
						unit.packages[0].other.addons = {
							parking: [],
							lockers: [],
							bikeRacks: [],
						};
					} else if (!unit.packages[0].other.addons[addOnType]) {
						unit.packages[0].other.addons[addOnType] = [];
					}

					const foundPackageAddOn = unit.packages[0].other.addons[
						addOnType
					].findIndex((x) => x.id == newAddOn.id);

					if (foundPackageAddOn !== -1)
						if (newAddOn.unitId) {
							unit.packages[0].other.addons[addOnType][
								foundPackageAddOn
							] = newAddOn;
						} else {
							unit.packages[0].other.addons[addOnType].splice(
								foundPackageAddOn,
								1
							);
						}
					else {
						unit.packages[0].other.addons[addOnType].push(newAddOn);
					}

					sendObj.packages = unit.packages;

					this.$api
						.put(
							`/units/:instance/${condoUnitId}/condounits`,
							sendObj
						)
						.then(() => {
							this.$store.commit(
								"UPDATE_CONDOADDONS",
								sendObj.options.addons
							);
							this.$store.commit("UPDATE_CONDO_SETTINGS", {
								...this.condoSettings,
								options: sendObj.options,
							});
							this.$store.commit("UPDATE_UNITS", unit);
							this.onClose();
							this.$message.success(
								"Selected Add On updated successfully."
							);
						})
						.catch((err) => {
							console.error(
								"Error error occurred while updating addon",
								err
							);
							this.$message.error(
								"Error error occurred while updating addon. Please try again"
							);
						});
				}
			} else {
				sendObj.options.addons[addOnType][foundAddOn] = {
					...newAddOn,
					unitId: "",
				};
				this.$api
					.put(`/settings/:instance/condounits`, {
						options: sendObj.options,
					})
					.then(({ data }) => {
						this.$store.commit(
							"UPDATE_CONDOADDONS",
							sendObj.options.addons
						);
						this.$store.commit("UPDATE_CONDO_SETTINGS", data);
						this.onClose();
						this.$message.success(
							"Selected Add On updated successfully."
						);
					})
					.catch((err) => {
						console.error(err);
						this.$message.error(
							"An error occurred. Please try again"
						);
					});
			}
		},

		openFilter() {
			this.$store.commit("OPEN_FILTER");
		},
	},
};
</script>

<style scoped lang="scss">
.overview-card {
	padding: 15px;
	border-radius: 4px;
	border: 1px solid #e6eaee;
	background-color: #fff;
}

@media screen and (max-width: 750px) {
	.last-item {
		grid-column: 1 / span 2;
	}
}

.grid-display-2 {
	display: grid;
	gap: 1rem;

	@media screen and (min-width: 1100px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media screen and (max-width: 1100px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.gap-10 {
	gap: 2.5rem;
}

.gap-5 {
	gap: 1.25rem;
}
</style>
