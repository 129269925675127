<template>
	<div class="display-card p-3 dF aT h-full" :data-addon-id="this.addon.id">
		<div class="dF w-full">
			<div class="f1">
				<div class="dF jSB">
					<div class="dF aC" style="gap: 5px">
						<div style="height: 32px" class="mr-2 dF aC">
							<a-checkbox :checked="selectAddon.includes(id)" @change="$emit('toggleSelect', id, type)" />
						</div>

						<h5 style="margin-bottom: 0">
							{{
								type === "parking"
								? "Parking"
								: type === "lockers"
									? "Locker"
									: "Bike Rack"
							}}
							#{{ addon.name }}
						</h5>
						<small v-if="addon.allocatedTo"
							style="font-weight:300; background:var(--light-bg-gray); border-radius:40px;"
							class="px-3 py-2 inline-block">
							<a-badge color="var(--primary)" :text="`${getAssigneeName(addon.allocatedTo)}`" />
						</small>
					</div>
					<div class="dF aC">
						<div class="dF aC">
							<div class="mr-2" style="color: #9ea0a5">
								Tag(s)
							</div>
							<a-select :disabled="addon && addon.status !== 'available'
								" @change="(e) => applyTag(addon, e)" size="small" v-model="addon.tags" mode="multiple" style="width: 200px">
								<a-select-option v-for="tag in tags" :key="tag.label">{{ tag.label }}</a-select-option>
							</a-select>
						</div>
						<div class="mx-2 px-2 dF aC" style="
                                border-radius: 4px;
                                font-size: 12px;
                                height: 24px;
                            "
							:style="`background-color:${statusColorCode[addon.status] || 'var(--black)'}; color: ${addon.status != 'hold' ? '#FFF' : ''};`">
							<div>{{ (salesStatus[addon.status] || addon.status).toUpperCase() }}</div>
						</div>
						<a-tooltip overlayClassName="change-tooltip-color" placement="topRight" title="Only Owner allowed to perform any action on add ons."
							v-if="$userType === 'agent'">
							<a-button :disabled="true" shape="circle" icon="lock" />
						</a-tooltip>
						<a-tooltip overlayClassName="change-tooltip-color" placement="topRight" :title="['allocated', 'approved_for_reservation', 'reserved'].includes(addon.status)
							? 'This Add On is locked as its currently in a worksheet. Please check the Worksheet App'
							: addon.status === 'sold'
								? 'This Add On has been SOLD. A mutual release is required to Void the sale.'
								: 'This Add On is locked as its currently in a Transaction. Please check the Transaction App'
							" v-else-if="(addon.status !== 'available' || ['allocated', 'approved_for_reservation', 'reserved'].includes(addon.status))">
							<a-button :disabled="true" shape="circle" icon="lock" />
						</a-tooltip>
						<MoreActions v-else trigger="click" :items="showActions(addon)" />
					</div>
				</div>
				<div class="ml-4">
					<div class="grid-display-4 mt-3">
						<div>
							<div style="color: #9ea0a5">
								{{
									type === "parking"
									? "Parking"
									: type === "lockers"
										? "Locker"
										: "Bike Rack"
								}}
								ID
							</div>
							<h5>
								{{
									type === "parking"
									? addon.pID
									: type === "lockers"
										? addon.lID
										: addon.bID || "N/A"
								}}
							</h5>
						</div>
						<div>
							<div style="color: #9ea0a5">
								{{
									type === "parking"
									? "Parking"
									: type === "lockers"
										? "Locker"
										: "Bike Rack"
								}}
								#
							</div>
							<h5>{{ addon.name || "N/A" }}</h5>
						</div>
						<div v-if="type === 'parking'">
							<div style="color: #9ea0a5">Parking Type</div>
							<h5>{{ addon.type || "N/A" }}</h5>
						</div>
						<div v-if="type === 'lockers'">
							<div style="color: #9ea0a5">Locker Type</div>
							<h5>{{ addon.type || "N/A" }}</h5>
						</div>
						<div v-if="type === 'bikeRacks'">
							<div style="color: #9ea0a5">Bike Rack Type</div>
							<h5>{{ addon.type || "N/A" }}</h5>
						</div>
						<div>
							<div style="color: #9ea0a5">
								{{
									type === "parking"
									? "Parking"
									: type === "lockers"
										? "Locker"
										: "Bike Rack"
								}}
								Price
							</div>
							<h5>${{ addon.price && addon.price.toLocaleString() }}</h5>
						</div>
						<div>
							<div style="color: #9ea0a5">Unit</div>
							<h5>
								{{
									(addon.unitId &&
										units[addon.unitId] &&
										units[addon.unitId].unitNumber) ||
									"N/A"
								}}
							</h5>
						</div>
						<div>
                            <div style="color: #9ea0a5">Commission</div>
                            <h5 v-if="addon.commissionStructure && addon.commissionStructure.commissionAmount">
                                {{ addon.commissionStructure.commissionType === 'amount' ? '$': '' }} {{
                                    addon.commissionStructure.commissionAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                }} {{ addon.commissionStructure.commissionType === 'percentage' ? '%': '' }}
                            </h5>
							<h5 v-else-if="agentCommissionStructure && agentCommissionStructure.commissionAmount">
                                {{ agentCommissionStructure.commissionType === 'amount' ? '$': '' }}{{
                                    agentCommissionStructure.commissionAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                }} {{ agentCommissionStructure.commissionType === 'percentage' ? '%': '' }}
                            </h5>
                        </div>
					</div>

					<hr class="my-4" />

					<div style="color: #9ea0a5">
						{{
							type === "parking"
							? "Parking"
							: type === "lockers"
								? "Locker"
								: "Bike Rack"
						}}
						Description
					</div>
					<h6>
						{{ addon.description }}
					</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MoreActions from "bh-mod/components/common/MoreActions";
export default {
	components: { MoreActions },
	props: ["type", "id", "selectAddon"],
	data() {
		return {
			salesStatus: {
				available: "Available",
				sold: "Sold",
				hold: "Hold",
				conditional: "Sold Conditional",
				inventory: "Standing Inventory",
				notreleased: "Not Released",
				allocated: "Allocated",
				approved_for_reservation: "Approved",
				reserved: "Reserved"
			},
			statusColorCode: {
				available: "#1EC48C",
				sold: "#FD6C83",
				hold: "#FECE5B",
				conditional: "#9693E8",
				notreleased: "#3F3356",
				allocated: "var(--primary)",
				approved_for_reservation: "#134F5C",
				reserved: "#419E00"
			}
		}
	},
	computed: {
		condoAddons() {
			return this.$store.state.inventory.condoAddons;
		},
		addon() {
			let selectedAddOn = {}
			if (this.type === "parking") {
				selectedAddOn = (
					this.condoAddons.parking.find((x) => x.id === this.id) || {}
				);
			} else if (this.type === "lockers") {
				selectedAddOn = (
					this.condoAddons.lockers.find((x) => x.id === this.id) || {}
				);
			} else {
				selectedAddOn = (
					this.condoAddons.bikeRacks.find((x) => x.id === this.id) ||
					{}
				);
			}

			if (selectedAddOn.tags && selectedAddOn.tags.length && this.tags.length) {
				selectedAddOn.tags = selectedAddOn.tags.filter(id => {
					return this.tags.find(t => t.label === id)
				})
			}

			return selectedAddOn
		},
		units() {
			let units = {};
			Object.values(this.$store.state.inventory.units).forEach((unit) => {
				if (unit.buildingType === "condounits") {
					units[unit.id] = unit;
				}
			});
			return units;
		},
		storeTags() {
			return this.$store.state.inventory.tags || {};
		},
		tags() {
			let list = [];

			if (Object.keys(this.storeTags).length) {
				let tags = Object.values(this.storeTags).filter(
					(x) =>
						x &&
						x.name &&
						x.id &&
						x.type ===
						(this.instance.productType === "lowrise"
							? "lots"
							: "models")
				);
				tags.forEach((tag) => {
					if (tag && tag.id && tag.name) {
						let obj = {};
						obj.value = tag.id;
						obj.label = tag.name;
						list.push(obj);
					}
				});
			}
			return list;
		},
		instance() {
			return this.$store.state.instance;
		},

		realtors() {
			return this.$store.state.realtors
		},

		user() {
			return this.$store.state.user.user
		},

		appSettings() {
			let app = this.$store.state.inventory.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},

		agentCommissionStructure() {
			if (this.appSettings.agentCommissionType !== 'individual') {
				return {
					commissionType: this.appSettings.agentCommissionStructure && this.appSettings.agentCommissionStructure.commissionType || 'percentage',
					commissionAmount: this.appSettings.agentCommissionStructure && this.appSettings.agentCommissionStructure.commissionAmount || null
				}
			} else {
				return {
					commissionType: this.appSettings.agentCommissionStructure && this.appSettings.agentCommissionStructure.addOnCommissionType || 'percentage',
					commissionAmount: this.appSettings.agentCommissionStructure && this.appSettings.agentCommissionStructure.addOnCommissionAmount || null
				}
			}
		},
	},
	methods: {
		applyTag(addOn) {
			this.$emit("applyTags", addOn);
		},

		showActions(addon) {
			let { status } = addon;
			return [
				{
					label: "Update Status",
					value: "updateStatus",
					val: "status",
					action: () => this.$emit("updateStatus"),
					if: !status.includes("sold"),
					icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
				}
			].filter((x) => x.if);
		},

		getAssigneeName(id) {
			if (this.$userType === 'agent') {
				return this.user.firstName + " " + this.user.lastName
			}
			const user = this.realtors.find(r => r.id === id)
			if (user) {
				return user.firstName + " " + user.lastName
			}
			return 'N/A'
		},
	},
};
</script>

<style lang="scss">
.unsell-button.ant-btn {
	border: 1px solid var(--orange);
	color: var(--orange);

	svg {
		fill: var(--orange);
	}
}

.unsell-button.ant-btn:hover {
	color: #fff;
	background-color: var(--orange);

	svg {
		fill: #fff;
	}
}
</style>

<style scoped lang="scss">
.lot-detail:hover {
	text-decoration: underline;
	cursor: pointer;
	color: var(--orange);
}

.grid-display-2 {
	display: grid;
	gap: 1rem;

	@media screen and (min-width: 1024px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media screen and (max-width: 1024px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.grid-display-4 {
	gap: 1rem;
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-display-5 {
	display: grid;
	gap: 1rem;

	@media screen and (min-width: 1024px) {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}

	@media screen and (max-width: 1024px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.grid-display-4 {
	display: grid;
	gap: 1rem;

	@media screen and (min-width: 1024px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	@media screen and (max-width: 1024px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}
</style>
