<template>
    <a-modal
        :visible="visible"
        width="500px"
        :footer="null"
        @cancel="onClose"
        :centered="true"
    >
        <BhLoading :show="loading" />
        <div class="mb-2">
            <h5 class="mb-4" v-text="'Bulk Apply Tags'" />

            <p class="mb-2" style="color: #40454c; font-size: 14px">
                Apply Tags<span style="color: var(--danger)"> *</span>
            </p>
            <a-select
                size="large"
                v-model="selectedTags"
                mode="multiple"
                class="w-full"
                placeholder="Apply tags"
            >
                <a-select-option v-for="tag in tags" :key="tag.value">{{
                    tag.label
                }}</a-select-option>
            </a-select>
        </div>
        <div class="dF jE mt-5">
            <a-button class="cancel-button" @click="onClose">CANCEL</a-button>
            <a-button class="ml-3" type="primary" @click="onOk"
                >UPDATE</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import BhLoading from "bh-mod/components/common/Loading";

export default {
    props: [
        "visible",
        "selectedLots",
        "lots",
        "selectedUnits",
        "units",
        "addOn",
    ],
    components: { BhLoading },
    data: () => ({
        selectedTags: [],
        loading: false,
    }),
    watch: {},
    computed: {
        siteplan() {
            return this.$store.state.inventory.siteplan.id;
        },
        storeTags() {
            return this.$store.state.inventory.tags || {};
        },
        tags() {
            let list = [];

            if (Object.keys(this.storeTags).length) {
                let tags = Object.values(this.storeTags).filter(
                    (x) =>
                        x &&
                        x.name &&
                        x.id &&
                        x.type ===
                            (this.instance.productType === "lowrise"
                                ? "lots"
                                : "models")
                );
                tags.forEach((tag) => {
                    if (tag && tag.id && tag.name) {
                        let obj = {};
                        obj.value = tag.id;
                        obj.label = tag.name;
                        list.push(obj);
                    }
                });
            }
            return list;
        },
        instance() {
            return this.$store.state.instance;
        },
    },
    methods: {
        async onOk() {
            if (this.selectedTags && this.selectedTags.length < 1)
                return this.$message.error("Please select a tag to apply.");

            if (this.addOn) {
                this.$emit("updateAddOnTag", {
                    tags: this.selectedTags.map((t) => {
                        const found = this.tags.find((tt) => tt.value === t);
                        return found && found.label;
                    }),
                    addOnType: this.addOn,
                });
                this.selectedTags = [];
                return;
            }
            this.loading = true;
            if (this.instance.productType === "lowrise") {
                const lotsToUpdate = [];
                let updateError = false;
                this.lots.forEach(async (x) => {
                    if (this.selectedLots.includes(x.id)) {
                        if (x.transactionId && x.status !== "available") {
                            updateError = true;
                        } else {
                            lotsToUpdate.push(x.id);
                        }
                    }
                });
                if (updateError) {
                    this.$message.error(
                        "You can only apply tags to available lots."
                    );
                }
                if (!lotsToUpdate.length) {
                    this.loading = false;
                    this.onClose();
                    return;
                }
                let sendObj = {
                    lots: lotsToUpdate,
                    tags: JSON.parse(JSON.stringify(this.selectedTags)),
                };
                this.$api
                    .put(`/lots/:instance/${this.siteplan}/bulkTag`, sendObj)
                    .then(() => {
                        this.$emit("applyBulkTags", this.selectedTags);
                        this.onClose();
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                        console.error(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                const unitsToUpdate = [];
                let updateError = false;
                this.units.forEach(async (x) => {
                    if (this.selectedUnits.includes(x.id)) {
                        if (x.transactionId && x.salesStatus !== "available") {
                            updateError = true;
                        } else {
                            unitsToUpdate.push(x.id);
                        }
                    }
                });
                if (updateError) {
                    this.$message.error(
                        "You can only apply tags to available units."
                    );
                }
                if (!unitsToUpdate.length) {
                    this.loading = false;
                    this.onClose();
                    return;
                }
                let sendObj = {
                    units: unitsToUpdate,
                    tags: JSON.parse(JSON.stringify(this.selectedTags)),
                };
                this.$api
                    .put(`/units/:instance/bulkTag`, sendObj)
                    .then(({ data }) => {
                        this.$emit("applyBulkTags", this.selectedTags);
                        this.onClose();
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                        console.error(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        onClose() {
            this.$emit("closeBulkTags");
            this.selectedTags = [];
        },
    },
};
</script>

<style>
</style>
